<style lang="scss">
.user-data-form {
  .v-autocomplete {
    input,
    ::placeholder {
      font-size: 16px !important;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    [class*=" col-"] {
      padding: 0 12px !important;
    }
  }

  .w-100 {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      min-width: 75%;
    }
  }
  .no-border {
    border: none !important;
  }
}

.melaregalo-invoice {
  .custom-text {
    color: var(--v-primary-base) !important;
  }
}
</style>
<template>
  <div
    class="melaregalo-invoice payment-invoice  mt-2 custom-text"
    v-if="smartCart.personFiscal"
  >
    <transition-expand>
      <v-card
        :loading="loading"
        elevation="0"
        class="user-data-form custom-text"
      >
        <v-form
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
          @submit.prevent="updateBillingDetails"
        >
          <v-row class="no-border">
            <br />
            <v-col cols="12">
              <v-radio-group
                v-model="smartCart.personFiscal.personTypeId"
                row
                class="w-100 mt-0"
                @change="changed"
                :mandatory="false"
              >
                <v-radio
                  color="default"
                  :class="$vuetify.breakpoint.xs ? 'w-100' : 'w-30'"
                  :value="'2'"
                  style="min-width: 120px;"
                  ><template v-slot:label>
                    <span class="ml-2 text-h6 font-weight-bold">
                      {{ $t("paymentInvoice.userCompany") }}
                    </span>
                  </template></v-radio
                >
                <v-radio
                  color="default"
                  :class="$vuetify.breakpoint.xs ? 'w-100' : 'w-30'"
                  :value="'1'"
                  ><template v-slot:label>
                    <span class="ml-2 text-h6 font-weight-bold">
                      {{ $t("paymentInvoice.userPrivate") }}
                    </span>
                  </template>
                </v-radio>

                <v-radio
                  color="default"
                  :class="$vuetify.breakpoint.xs ? 'w-100' : 'w-30'"
                  :value="'4'"
                  ><template v-slot:label>
                    <span class="ml-2 text-h6 font-weight-bold">
                      {{ $t("paymentInvoice.publicAdministration") }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <!-- PRIVATO -->
          <template v-if="smartCart.personFiscal.personTypeId == 1">
            <v-row class="no-border">
              <v-col cols="6" sm="3" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.firstName"
                  :label="$t('profile.firstName') + ' *'"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  required
                  :rules="[requiredValueNoMsg()]"
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.lastName"
                  :label="$t('profile.lastName') + ' *'"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  required
                  :rules="[requiredValueNoMsg()]"
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>

              <!--
    <v-col cols="12" sm="6" class="pb-0">
      <v-text-field
        v-model="smartCart.personFiscal.company"
        :label="$t('paymentInvoice.owner')"
        class="rounded-sm"
        clearable
        dense
        outlined
        flat
        required
        :rules="[requiredValueNoMsg()]"
        @blur="$refs.form.validate()"
        :disabled="isReadOnly"
      ></v-text-field>
    </v-col>

-->

              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.fiscalCode"
                  :label="$t('paymentInvoice.fiscalCode') + ' *'"
                  :rules="cfRules"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  required
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="no-border">
              <v-col cols="12" sm="12" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.extraInfo4"
                  :label="$t('profile.tigrosCard')"
                  :placeholder="`${$t('profile.tigrosCardPlaceholder')}`"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="no-border">
              <v-col col="12" sm="9" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.address"
                  :label="$t('paymentInvoice.address') + ' *'"
                  :rules="[requiredValueNoMsg()]"
                  required
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.addressNumber"
                  :label="$t('paymentInvoice.addressNumber') + ' *'"
                  :rules="[requiredValueNoMsg()]"
                  required
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.city"
                  :label="$t('paymentInvoice.city') + ' *'"
                  :rules="[requiredValueNoMsg()]"
                  required
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.postalcode"
                  :label="$t('paymentInvoice.postalCode') + ' *'"
                  :rules="[requiredValueNoMsg()]"
                  required
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.province"
                  :label="$t('paymentInvoice.province') + ' *'"
                  :rules="provinceRules"
                  required
                  max-length
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>

              <v-col cols="6" sm="6" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.extraInfo3"
                  :label="$t('profile.email.email') + ' *'"
                  required
                  max-length
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  :rules="emailRules"
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>

              <v-col cols="6" sm="6" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.extraInfo2"
                  :label="$t('profile.phone') + ' *'"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  required
                  :rules="[requiredValueNoMsg()]"
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <!-- AZIENDA / PUBBLICA AMMINISTRAZIONE -->
          <template
            v-else-if="
              smartCart.personFiscal.personTypeId == 2 ||
                smartCart.personFiscal.personTypeId == 4
            "
          >
            <v-row class="no-border">
              <!-- partita iva azienda -->
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.vatCode"
                  :label="$t('paymentInvoice.vatCode') + ' *'"
                  :rules="cfIvaRules"
                  required
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <!-- ragione sociale azienda -->
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.company"
                  :label="$t('paymentInvoice.company') + ' *'"
                  :rules="[requiredValueNoMsg()]"
                  required
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <!-- codice fiscale azienda -->
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.vatFiscalCode"
                  :label="$t('paymentInvoice.vatFiscalCode') + ' *'"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  required
                  :rules="cfIvaRules"
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <!--
                smartCart.personFiscal.personTypeId == 2
              -->
              <v-col cols="0" sm="6" class="pb-0"></v-col>

              <!-- codice IPA PUBBLICA AMMINISTRAZIONE -->
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
                v-if="smartCart.personFiscal.personTypeId == 4"
              >
                <v-text-field
                  v-model="codIpa"
                  :label="$t('paymentInvoice.codIPA') + ' *'"
                  class="rounded-sm"
                  counter="6"
                  clearable
                  dense
                  outlined
                  flat
                  required
                  :rules="codIpaRules"
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>

              <!-- pec azienda -->
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
                v-if="smartCart.personFiscal.personTypeId == 2"
              >
                <v-text-field
                  v-model="smartCart.personFiscal.eMailCertified"
                  :label="`@${$t('paymentInvoice.pec')}` + ' *'"
                  :rules="emailPecRules"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <!-- sdi azienda -->
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
                v-if="smartCart.personFiscal.personTypeId == 2"
              >
                <v-text-field
                  v-model="smartCart.personFiscal.vatSdiCode"
                  :label="$t('paymentInvoice.vatSdiCode') + ' SDI *'"
                  :rules="sdiRules"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>

              <!-- CIG pubblica amministrazione -->
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
                v-if="smartCart.personFiscal.personTypeId == 4"
              >
                <v-text-field
                  v-model="codCig"
                  :label="$t('paymentInvoice.cig')"
                  :rules="codCigRules"
                  :counter="10"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>

              <!-- Nr Determina pubblica amministrazione -->
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
                v-if="smartCart.personFiscal.personTypeId == 4"
              >
                <v-text-field
                  maxLength="13"
                  v-model="nrDetermina"
                  ref="nrDetermina"
                  :label="$t('paymentInvoice.nrDetermina')"
                  :rules="nrDeterminaRules"
                  counter="13"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  @blur="$refs.form.validate()"
                  @click:clear="clearDetermina"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>

              <!-- Data Determina pubblica amministrazione -->
              <v-col
                cols="12"
                sm="6"
                class="pb-0"
                v-if="smartCart.personFiscal.personTypeId == 4"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  :disabled="!isNrDeterminaValid"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dataDetermina"
                      :label="$t('paymentInvoice.dataDetermina')"
                      :rules="dataDeterminaRules"
                      ref="dataDetermina"
                      :placeholder="$t('paymentInvoice.dataDetermina')"
                      :required="isNrDeterminaValid"
                      :disabled="!isNrDeterminaValid"
                      outlined
                      readonly
                      dense
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon v-bind="attrs" v-on="on" slot="append">
                        $editcalendar
                      </v-icon>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="it-IT"
                    ref="picker"
                    v-model="dataDetermina"
                    :id="'id_' + Math.random()"
                    min="1910-01-01"
                    first-day-of-week="1"
                    :readonly="!nrDetermina"
                    type="date"
                    no-title
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <!-- welfare -->

              <v-col
                cols="12"
                sm="12"
                v-if="smartCart.personFiscal.personTypeId == 2"
              >
                <strong>
                  {{ $t("melaregalo.invoice.wantWelfareInInvoice") }}
                </strong>
                <v-checkbox
                  v-model="welfareMelaregalo"
                  true-value="true"
                  false-value="false"
                />
              </v-col>
              <br />
              <br />
              <!-- indirizzo azienda -->
              <v-col col="12" sm="9" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.address"
                  :label="$t('paymentInvoice.address') + ' *'"
                  :rules="[requiredValueNoMsg()]"
                  required
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <!-- civico azienda -->
              <v-col cols="12" sm="3" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.addressNumber"
                  :label="$t('paymentInvoice.addressNumber') + ' *'"
                  :rules="[requiredValueNoMsg()]"
                  required
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <!-- città azienda -->
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.city"
                  :label="$t('paymentInvoice.city') + ' *'"
                  :rules="[requiredValueNoMsg()]"
                  required
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <!-- cap azienda -->
              <v-col cols="12" sm="3" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.postalcode"
                  :label="$t('paymentInvoice.postalCode') + ' *'"
                  :rules="[requiredValueNoMsg()]"
                  required
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <!-- provincia azienda -->
              <v-col cols="12" sm="3" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.province"
                  :label="$t('paymentInvoice.province') + ' *'"
                  :rules="provinceRules"
                  required
                  max-length
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="no-border">
              <v-col cols="12" sm="12" class="pb-0">
                <strong>
                  {{ $t("melaregalo.invoice.companyContact") }}
                </strong>
              </v-col>
            </v-row>
            <br />
            <br />

            <v-row class="no-border">
              <v-col
                cols="6"
                sm="6"
                class="pb-0"
                v-if="smartCart.personFiscal.personTypeId == 2"
              >
                <v-text-field
                  v-model="smartCart.personFiscal.extraInfo1"
                  :label="$t('paymentInvoice.owner') + ' *'"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  required
                  :rules="[requiredValueNoMsg()]"
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>

              <v-col
                cols="6"
                sm="6"
                class="pb-0"
                v-if="smartCart.personFiscal.personTypeId == 4"
              >
                <v-text-field
                  v-model="smartCart.personFiscal.extraInfo1"
                  :label="$t('paymentInvoice.referPerson') + ' *'"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  required
                  :rules="[requiredValueNoMsg()]"
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>

              <v-col cols="6" sm="6" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.extraInfo4"
                  :label="$t('profile.tigrosCard')"
                  :placeholder="`${$t('profile.tigrosCardPlaceholder')}`"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="no-border">
              <v-col cols="6" sm="6" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.extraInfo2"
                  :label="$t('profile.phone') + ' *'"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  required
                  :rules="[requiredValueNoMsg()]"
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" class="pb-0">
                <v-text-field
                  v-model="smartCart.personFiscal.extraInfo3"
                  :label="$t('profile.email.email') + ' *'"
                  class="rounded-sm"
                  clearable
                  dense
                  outlined
                  flat
                  required
                  :rules="emailRules"
                  @blur="$refs.form.validate()"
                  :disabled="isReadOnly"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="no-border">
              <v-col cols="12" class="d-flex justify-end">
                <v-btn
                  v-if="!isReadOnly && isShowBtnSave"
                  type="submit"
                  x-large
                  color="primary"
                  depressed
                  :disabled="disabledUpdate"
                >
                  {{ $t("paymentInvoice.save") }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-form>

        <div v-if="smartCart.personFiscal.personTypeId == 2">
          <br />
          <br />
          <span>{{ $t("melaregalo.invoice.lawDisclaimer") }}</span>
        </div>
      </v-card>
    </transition-expand>
  </div>
</template>

<script>
import TransitionExpand from "@/components/TransitionExpand.vue";
// import RegistrationService from "~/service/userService";
import SmartcartService from "~/service/smartcartService";
import { req } from "~/validator/commons";
import { mapActions, mapGetters } from "vuex";
import { mapCartInfo } from "~/service/ebsn";
import recaptcha from "~/mixins/recaptcha";
import tigrosCustomService from "@/service/tigrosCustomService";

import {
  requiredValueNoMsg,
  requiredValue,
  isCF,
  isCfIva,
  isLength,
  isInteger,
  minLength,
  maxLength
} from "~/validator/validationRules";

export default {
  name: "MelaregaloInvoice",
  mixins: [recaptcha],
  props: {
    isShowBtnSave: { type: Boolean, default: true },
    smartCart: { type: Object, required: true }
  },

  data() {
    return {
      menu: false,
      loading: false,
      valid: false,
      lazy: false,
      dirty: false,
      address: null,
      hasBirthDate: false,
      isChangePersonType: true,
      welfareMelaregalo: false,
      codCig: null,
      nrDetermina: null,
      dataDetermina: null,
      codIpa: null,
      requiredValueNoMsg: requiredValueNoMsg,
      requiredValue: requiredValue,
      provinceRules: [
        requiredValueNoMsg(),
        isLength(2, "La provincia deve avere esattamente 2 caratteri")
      ],

      codIpaRules: [
        requiredValueNoMsg(),
        isLength(6, "Il Codice Ipa deve avere esattamente 6 caratteri")
      ],

      codCigRules: [
        isLength(
          10,
          "Il Codice Identificativo di Gara deve avere esattamente 10 caratteri"
        )
      ],
      nrDeterminaRules: [
        isInteger(),
        minLength(1, "Il numero minimo di cifre accetate è 1"),
        maxLength(13, "Il numero minimo di cifre accetate è 13")
        /* v => {
          if (v != null && v != "") {
            if (v.length != 13) {
              this.dataDetermina = null;
              return "Il Nr. Determina deve avere un numero di 13 cifre";
            } else {
              this.menu = true;
            }
          } else if (v == null || v == "") {
            this.dataDetermina = null;
            this.menu = false;
            return true;
          } else {
            return true;
          }
        }*/
      ],
      userPrivate: null,
      cfRules: [requiredValueNoMsg(), isCF()],
      emailRules: [
        requiredValueNoMsg(),
        v => {
          if (v) {
            return (
              !req(v) ||
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
              "Digitare una e-mail valida"
            );
          } else {
            return true;
          }
        }
      ],
      emailPecRules: [
        v => {
          if (v) {
            return (
              !req(v) ||
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
              "Digitare una e-mail valida"
            );
          } else if (
            this.smartCart.personFiscal &&
            !this.smartCart.personFiscal.vatSdiCode
          ) {
            return "";
          } else {
            return true;
          }
        }
      ],
      sdiRules: [
        v => {
          if (v) {
            return /^[A-Z0-9]{6,7}$/.test(v) || "Codice SDI non valido";
          } else if (
            this.smartCart.personFiscal &&
            !this.smartCart.personFiscal.eMailCertified
          ) {
            return "";
          } else {
            return true;
          }
        }
      ],
      cfIvaRules: [requiredValueNoMsg(), isCfIva()],
      postalcodeList: [],
      city: null,
      address1: null
    };
  },
  components: { TransitionExpand },
  computed: {
    ...mapGetters({
      serviceId: "cart/getSelectedService"
    }),
    //...mapCartInfo({ welfareChoice: "is_melaregalo_welfare" }),
    ...mapActions({ welfareChoice: "is_melaregalo_welfare" }),
    disabledUpdate() {
      return !this.valid;
    },
    isNrDeterminaValid() {
      if (typeof this.nrDetermina === "string") {
        return (
          !isNaN(this.nrDetermina) &&
          this.nrDetermina.length > 0 &&
          this.nrDetermina.length <= 13
        );
      }
      return false;
    },
    dataDeterminaRules() {
      return [this.isNrDeterminaValid ? requiredValue() : null];
    },
    isReadOnly() {
      var result = false;

      // if (this.userPrivate) {
      //   return this.smartCart.personFiscal.fiscalCode.length > 0;
      // } else {
      //   return (
      //     this.smartCart.personFiscal.vatCode.length > 0 &&
      //     this.smartCart.personFiscal.company.length > 0
      //   );
      // }

      if (this.smartCart.personFiscal.userId > 0) {
        result = false;
      } else {
        result = false;
      }

      return result;
    },

    ...mapCartInfo({ is_melaregalo_welfare: "is_melaregalo_welfare" })
  },
  methods: {
    ...mapActions({
      setCartInfo: "smart-cart/setCartInfo"
    }),

    clearDetermina() {
      this.dataDetermina = "";
      this.menu = false;
      this.$refs.nrDetermina.blur();
      this.$refs.dataDetermina.blur();
    },

    async handleInvoiceChange() {
      if (this.needInvoice) {
        this.validate();
      } else {
        this.$emit("billingFormCompleted", true);
      }
    },
    changed(value) {
      this.dirty = true;
      this.smartCart.personFiscal.personTypeId = value;
      let data = {
        personTypeId: value
      };
      global.EventBus.$emit("check_disable_button_next", data);
      this.validate();
    },
    validate() {
      let _this = this;

      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          _this.$emit("billingFormCompleted", _this.valid);
        }
      }, 200);
    },
    async updateBillingAddress() {
      try {
        if (this.$refs.form.validate()) {
          let res = await SmartcartService.updatePersonFiscal(
            7,
            this.smartCart.personFiscal
          );
          /*
          if (res && res.billingAddress) {
            this.smartCart.personFiscal = res;
            if (this.smartCart.personFiscal.birthDate) {
              this.hasBirthDate = true;
            }

          }
          */
          if (res) {
            global.EventBus.$emit("success_billing_address_melaregalo");
          }
          this.dirty = false;
          this.validate();
        }
      } catch (err) {
        global.EventBus.$emit("error_billing_address_melaregalo");

        this.response = err;
      }
    }
  },
  // preSet() {
  //     this.personTypeId = this.smartCart.personFiscal.personTypeId;
  //   },
  async mounted() {
    // await this.getUserDetail();

    //  this.personType = this.smartCart.personFiscal.personTypeId;
    this.validate();
  },
  created() {
    //ascolto evento
    global.EventBus.$on("update_billing_address_melaregalo", () => {
      this.updateBillingAddress();
    });
    if (
      this.smartCart.cartInfos.is_melaregalo_fisicalcard == null ||
      this.smartCart.cartInfos.is_melaregalo_fisicalcard === undefined
    ) {
      tigrosCustomService.setCartInfo(
        "is_melaregalo_fisicalcard",
        true,
        undefined,
        7
      );
      this.smartCart.cartInfos.is_melaregalo_fisicalcard = true;
    }
    if (
      !this.smartCart.cartInfos.is_melaregalo_welfare ||
      this.smartCart.cartInfos.is_melaregalo_welfare === "false"
    ) {
      tigrosCustomService.setCartInfo(
        "is_melaregalo_welfare",
        "false",
        undefined,
        7
      );
      this.welfareMelaregalo = false;
    } else {
      this.welfareMelaregalo = this.smartCart.cartInfos.is_melaregalo_welfare;
    }

    if (
      !this.smartCart.cartInfos.cod_cig ||
      this.smartCart.cartInfos.cod_cig === undefined
    ) {
      tigrosCustomService.setCartInfo("cod_cig", "", undefined, 7);
      this.codCig = null;
    } else {
      this.codCig = this.smartCart.cartInfos.cod_cig;
    }

    if (
      !this.smartCart.cartInfos.data_determina ||
      this.smartCart.cartInfos.data_determina === undefined
    ) {
      tigrosCustomService.setCartInfo("data_determina", "", undefined, 7);
      this.dataDetermina = null;
    } else {
      this.dataDetermina = this.smartCart.cartInfos.data_determina;
    }

    if (
      !this.smartCart.cartInfos.nr_determina ||
      this.smartCart.cartInfos.nr_determina === undefined
    ) {
      tigrosCustomService.setCartInfo("nr_determina", "", undefined, 7);
      this.nrDetermina = null;
    } else {
      this.nrDetermina = this.smartCart.cartInfos.nr_determina;
    }

    if (
      !this.smartCart.cartInfos.cod_ipa ||
      this.smartCart.cartInfos.cod_ipa === undefined
    ) {
      tigrosCustomService.setCartInfo("cod_ipa", "", undefined, 7);
      this.codIpa = null;
    } else {
      this.codIpa = this.smartCart.cartInfos.cod_ipa;
    }

    let data = {
      personTypeId: this.smartCart.personFiscal.personTypeId
    };

    global.EventBus.$emit("check_disable_button_next", data);
  },
  watch: {
    welfareMelaregalo(val, oldVal) {
      if (val !== oldVal) {
        tigrosCustomService.setCartInfo(
          "is_melaregalo_welfare",
          val,
          undefined,
          7
        );
        this.smartCart.cartInfos.is_melaregalo_welfare = val;
      }
    },

    codCig(val, oldVal) {
      if (val !== oldVal) {
        tigrosCustomService.setCartInfo("cod_cig", val, undefined, 7);
        this.smartCart.cartInfos.cod_cig = val;
      }
    },
    dataDetermina(val, oldVal) {
      if (val !== oldVal) {
        tigrosCustomService.setCartInfo("data_determina", val, undefined, 7);
        this.smartCart.cartInfos.data_determina = val;
        this.dataDetermina = val;
      }
    },
    nrDetermina(val, oldVal) {
      if (val !== oldVal) {
        tigrosCustomService.setCartInfo("nr_determina", val, undefined, 7);
        this.smartCart.cartInfos.nr_determina = val;
      }
    },
    codIpa(val, oldVal) {
      if (val !== oldVal) {
        tigrosCustomService.setCartInfo("cod_ipa", val, undefined, 7);
        this.smartCart.cartInfos.cod_ipa = val;
      }
    }
  }
};
</script>
